<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="container-fluid py-4 add-student grade-detail paid-remain">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    {{$t('message.levelStudents')}}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-12 tab-div tab2">
            </div>
            <!-- exceptional attendance row -->
            <div class="" id="">
              <div

              >
               <div
              >
                <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label> {{$t('message.fromDate')}} <span class="red">*</span></label>
                     <vmd-input id="" type="date" v-model="fromDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> {{$t('message.toDate')}} <span class="red">*</span></label>
                     <vmd-input id="" type="date" v-model="toDate" />
                  </div>
                   <div class="mb-3 col-lg-3">
                  <label>
                    {{$t('message.department')}}<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.department_id ? errors.department_id[0] : ''
                    }}</span>
                  </label>
                  <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedDepartment"
                        :options='departments'
                        :searchable='true'
                        :close-on-select='true'
                        @select="getSubjects()"
                        :placeholder="$t('message.select') "
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div> <div class="mb-3 col-lg-3">
                  <label>
                    {{$t('message.subjects')}}
                    <span color="danger" class="error">{{
                      errors.department_id ? errors.department_id[0] : ''
                    }}</span>
                  </label>
                  <multiselect
                        class='required form-control'
                        id='group'
                        v-model="selectedSubject"
                        :options='subjectList'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select') "
                        @select='getSingleSubject()'
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>

                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="primary"
                      @click="filterData()"
                    >
                      {{$t('message.filter')}}</vmd-button
                    >
                  </div>
                <div
                  id="print-report"
                  style="margin-top: 40px; "
                >
                  <div
                    class="row align-center"
                    style="margin-top: 40px;"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"

                    >
                      <table class="b-block table" >
                        <thead>
                          <tr>
                              <th> {{$t('message.number')}}</th>
                             <th > {{$t('message.code')}}</th>
                            <th >  {{$t('message.name')}} </th>
                            <th > {{$t('message.phone')}}</th>
                            <th > {{$t('message.balance')}}</th>
                             <th> {{$t('message.branch')}}</th>
                               <th  v-for="subject in subjectList" :key="subject.id">
                                 {{subject.name}}</th>
                          </tr>
                        </thead>
                         <tbody>
                          <tr
                            v-for="(item, index) in enrolledStudents"
                            :key="item.id"
                          >
                           <td class="table-success">{{ index+1}}</td>
                           <td >{{ item.code}}</td>
                            <td >{{ item.full_name}}</td>
                             <td >{{ item.mobile}}</td>
                              <td >{{ item.wallet}}</td>
                              <td >{{ item.branch?.name}}</td>
                              <td v-for="subject in subjectList" :key="subject.id">
                                  <template v-if="hasMatchingSubject(item, subject.id)">
                                    {{ getInstructorName(item, subject.id) }} <br>
                                    <span style="font-size: 13px !important;" class="green total-tex">
                                    {{getDate(item, subject.id)}}
                                    </span>
                                  </template>
                                  <template v-if="hasMatchingSubjectMultiCourses(item, subject.id)">
                                       <table class ="course-table" style="width: 100%; height: 100%;">
                            <tr v-for="course in subjectCourses" :key="course.id">
                              <th  style="width: 100%; height: 100%; border-width: 0 !important;
                              border-bottom: 1px solid #ddd !important;">{{ course.name }}</th>
                              <td
                              style="width: 100%; height: 100%; border-width: 0 !important;
                              border-bottom: 1px solid #ddd !important;">
                                {{ course?.instructor?.name }}<br>
                                <span style="font-size: 13px !important;" class="green total-tex">
                                  {{ course.start }}
                                </span>
                              </td>
                            </tr>
                          </table>
                                  </template>
                                </td>

                            <!-- <td class="back-color total-tex">{{ calculateTotal(value) }}</td> -->
                              <!-- <td class="back-color">{{ value.total_month_test }}</td>
                             <template v-for="(subValue,subKey) in value" :key="subKey">
                             <td v-if="!subKey.includes('total')">
                              {{subValue.total_tests}}
                             </td>
                             </template>
                              <template v-for="(subValue,subKey) in value" :key="subKey">
                             <td v-if="!subKey.includes('total')">
                              {{subValue.total_booked}}
                             </td>
                             </template>
                             <td class="back-color">{{ value.total_month_booked }}</td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end -->
              </div>
              </div>
            </div>
          </div>
                         <!-- pagination -->
           <div class="row mt-3">
            <div class=" col-lg-9 col-sm-12 overflow-x">
  <nav aria-label="...">
    <ul class="pagination pagination-lg">
      <li @click="paginate('prev')" class="pointer" :class="{'pagi-active': currentPage === 1}">
        <span class="page-link pagi-item"><i class="fas fa-chevron-right"></i></span>
      </li>
      <li @click="paginate(page)" class="pointer" :class="{'pagi-active': currentPage === page}" v-for="page in visiblePages" :key="page">
        <span class="page-link pagi-item">{{ page }}</span>
      </li>
        <li v-if="showEllipsisAfter" class="pointer">
          <span class="page-link pagi-item">...</span>
        </li>
      <li @click="paginate('next')" class="pointer" :class="{'pagi-active': currentPage === allPages}">
        <span class="page-link pagi-item"><i class="fas fa-chevron-left"></i></span>
      </li>
    </ul>
  </nav>
  </div>
  <div class=" col-lg-3  text-center col col-sm-12" v-if="enrolledStudents?.length">
    <div class="">
      {{$t('message.showing')}} <span class="total-tex green">{{currentPage}}</span> {{$t('message.fromDate')}}   <span class="total-tex green">{{allPages}} </span> {{$t('message.page')}}
    </div>
  </div>
</div>
</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
export default {
  name: 'UsersPlacement',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      fromDate: '',
      toDate: '',
      branches: [],
      enrolledStudents: [],
      subjectList: [],
      selectedSubject: '',
      selectedDepartment: '',
      subjectCourses: [],
      departments: [],
      page: 1,
      currentPage: 1,
      allPages: 0,
      visiblePages: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}departments`).then((response) => {
      this.departments = response.data.data.map(value => ({
        name: value.name,
        id: value.id
      }))
    })
  },
  computed: {
    showEllipsisAfter () {
      return this.visiblePages[this.visiblePages.length - 1] < this.allPages
    }
  },
  methods: {
    getSubjects () {
      this.enrolledStudents = []
      this.subjectList = []
      this.$http.get(`${this.$hostUrl}subjects?department_id=${this.selectedDepartment.id}`).then((response) => {
        this.subjectList = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
    },
    getSingleSubject () {
      this.subjectList = []
      this.subjectList.push(this.selectedSubject)
    },
    filterData (page) {
      this.$http
        .get(`${this.$hostUrl}report/enrolled/students?from_date=${this.fromDate}&to_date=${this.toDate}&department_id=${this.selectedDepartment.id}&subject_id=${this.selectedSubject?.id || ''}&page=${page ?? ''}`
        )
        .then((response) => {
          this.enrolledStudents = response.data.data
          this.currentPage = response.data.current_page
          this.allPages = response.data.last_page
          const totalVisiblePages = 10 // Adjust the number of visible pages as per your requirement
          const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2))
          const endPage = Math.min(this.allPages, startPage + totalVisiblePages - 1)
          this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
        })
    },

    paginate (action) {
      let page = this.currentPage

      if (action === 'prev') {
        page = Math.max(1, page - 1) // Decrement page number, but keep it within bounds
      } else if (action === 'next') {
        page = Math.min(this.allPages, page + 1) // Increment page number, but keep it within bounds
      } else {
        page = parseInt(action, 10) // Go to the specified page
      }

      this.filterData(page)
    },

    hasMatchingSubject (item, headerId) {
      return Object.values(item.subjects).some((subjects) =>
        subjects.length === 1 && subjects.some((subject) => subject?.subject?.id === headerId)
      )
    },
    hasMatchingSubjectMultiCourses (item, headerId) {
      const subjectCourses = []
      Object.values(item.subjects).forEach((subjects) => {
        if (subjects.length > 1) {
          const matchingSubjects = subjects.filter((subject) => subject?.subject?.id === headerId)
          subjectCourses.push(...matchingSubjects)
        }
      })
      this.subjectCourses = subjectCourses
      return subjectCourses.length > 0
    },
    getInstructorName (item, headerId) {
      const subjectArrays = Object.values(item.subjects)
      for (let i = 0; i < subjectArrays.length; i++) {
        const subject = subjectArrays[i].find((item) => item?.subject?.id === headerId)
        if (subject) {
          return subject.instructor?.name || ''
        }
      }
      return ''
    },
    getDate (item, headerId) {
      const subjectArrays = Object.values(item.subjects)
      for (let i = 0; i < subjectArrays.length; i++) {
        const subject = subjectArrays[i].find((item) => item?.subject?.id === headerId)
        if (subject) {
          return subject?.start || ''
        }
      }
      return ''
    }

  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.course-table td,.course-table th,.course-table tr{
  border-width: 0 !important;
   border-bottom: 1px solid #ddd !important;
}
.course-table th{
  background: #f8f9fa;
  padding: 0 10px;
}
.course-table td{
  padding: 0 10px
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.width {
  width: 100% !important;
}
.back-color{
  background-color: #f8f9fa !important
}
.total-tex{
  font-weight: bold;
  font-size:16px !important;
  font-family: Montserrat, sans-serif !important;
}
</style>
